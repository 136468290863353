import { LOAN_TYPES } from '~/typings/enums/LoanType';

export const CONDITIONAL_INPUTS = {
  [LOAN_TYPES.FGTS_NEW]: [
    'name',
    'motherName',
    'document',
    'birthDate',
    'zipCode',
    'street',
    'addressNumber',
    'district',
    'city',
    'state',
    'bankNumber',
    'checkingAccount',
    'checkingAccountDigit',
    'agency',
    'agencyDigit',
  ],
  [LOAN_TYPES.CONSIGNED_CARD_RMC]: [
    'name',
    'motherName',
    'document',
    'registrationNumber',
    'registerNumberEmitter',
    'registerNumberState',
    'registerNumberDateOfIssue',
    'birthDate',
    'maritalStatus',
    'gender',
    'nationality',
    'cityOfBirth',
    'stateOfBirth',
  ],
  [LOAN_TYPES.BENEFIT_CARD_RCC]: [
    'name',
    'motherName',
    'document',
    'registrationNumber',
    'registerNumberEmitter',
    'registerNumberState',
    'registerNumberDateOfIssue',
    'birthDate',
    'maritalStatus',
    'gender',
    'nationality',
    'cityOfBirth',
    'stateOfBirth',
  ],
};
