import React, { useEffect } from 'react';
import screenRegister from '~/hoc/screenRegister';
import { Container } from '~/screens/Tickets/Tickets.styled';

import { Flex, Typography } from '~/ui/components';
import useUserRoles from '~/hooks/useUserRoles';
import { useAwaitControl } from 'react-redux-await-control';
import {
  createTicketAssessment,
  getTickets,
  selectAssessment,
  selectTicket,
  updateTicketAssessment,
} from '~/store/tickets/actions';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getLoggedUser } from '~/store/user/actions';
import { useTracking } from '~/hooks/useTracking';
import { ampli } from '~/ampli';
import { Assessment } from './Assessment/Assessment';
import { Content } from './Content/Content';
import { Search } from './Search/Search';

function Tickets() {
  const { ready, hasRole } = useUserRoles();

  const selectAssessmentControl = useAwaitControl(selectAssessment);
  const selectTicketControl = useAwaitControl(selectTicket);
  const updateAssessmentControl = useAwaitControl(updateTicketAssessment);
  const createAssessmentControl = useAwaitControl(createTicketAssessment);
  const getTicketsControl = useAwaitControl(getTickets);
  const loggedUserId = useAsyncActionStatus(getLoggedUser)?.result?.id;
  const { handleTracking } = useTracking();

  useEffect(
    () => () => {
      selectAssessmentControl.clear();
      selectTicketControl.clear();
      createAssessmentControl.clear();
      updateAssessmentControl.clear();
      getTicketsControl.clear();
    },
    [],
  );

  useEffect(() => {
    if (loggedUserId) {
      const event = ampli.visualizacaoVisualizouTela({
        titulo_tela: 'Tickets',
      });

      handleTracking(() => event);
    }
  }, [loggedUserId]);

  if (!ready) return <Container />;

  if (!hasRole('TICKET_MONITORING')) {
    return (
      <Container>
        <Flex ph={16} pv={16} align="center" justify="center">
          <Typography type="bodyMedium" weight={600}>
            Você não tem permissão para acessar essa tela
          </Typography>
        </Flex>
      </Container>
    );
  }

  return (
    <Container>
      <Search />
      <Content />
      <Assessment />
    </Container>
  );
}

export default screenRegister({
  screenName: 'Tickets',
  headerTitle: 'Tickets',
  path: '/backoffice/tickets',
})(Tickets);
