import { put, takeLatest } from 'redux-saga/effects';
import * as ContractActions from '~/store/contracts/actions';
import takeRequest from '~/store/takeRequest';

import * as ConsigneeActions from './actions';

export function* reloadContract({ meta: { payload } }: any) {
  yield put(ContractActions.getContractDetails.start({ id: payload?.contractId }));
  yield put(ConsigneeActions.updateConsigneeStatus.start({ contractId: payload?.contractId }));
}

export function* consigneeSaga(): Generator {
  yield takeRequest(ConsigneeActions.adjustContract);
  yield takeRequest(ConsigneeActions.sendContract);
  yield takeRequest(ConsigneeActions.updateConsigneeStatus);

  yield takeLatest(ConsigneeActions.adjustContract.success.toString(), reloadContract);
  yield takeLatest(ConsigneeActions.sendContract.success.toString(), reloadContract);
}
