import { Contract } from '~/typings/entities/contract';
import { LOAN_TYPES } from '~/typings/enums/LoanType';
import { Person } from './person';

export type GroupContract = {
  contractDate: string;
  contractId: number;
  lead: boolean;
  value: number;
};

export type KYCStatus = 'ONBOARDING' | 'TRANSACIONAL';

export class ContractInfo {
  private id: number;

  private contractId: number;

  private benefitAccountType: string | null;

  private readonly agency: string;

  private readonly agencyDigit: string;

  private readonly bankName: string;

  private bankNumber: string;

  private approvementDate: string;

  private checkingAccount: string;

  private checkingAccountDigit: string;

  private consigneeContractKey: string;

  private contractDate: string;

  private contractStatus: string;

  private loanType: string;

  private consigneeName: string;

  private createdLocation: string;

  private embeddedIof: number;

  private factorCode: string;

  private gender: string;

  private extraInformation: any;

  private installmentValue: number;

  private interestRate: number;

  private financialInstitutionBankNumber: number;

  private financedValue: number;

  private hasRefinancedContracts: boolean;

  private numberOfInstallments: number;

  private outstandingBalance: number;

  private paidInstallments: number;

  private validForSentToConsignee: boolean;

  private financialInstitution: any;

  private person: Person;

  private remainingInstallments: number;

  private situation: string;

  private termsAccepted: string;

  private type: string;

  private registerNumberDateOfIssue: string;

  private registerNumberEmitter: string;

  private registerNumberState: string;

  constructor(data: Contract) {
    this.id = data.id;
    this.contractId = data.contractId;
    this.agency = data.agency;
    this.agencyDigit = data.agencyDigit;
    this.bankName = data.bankName;
    this.bankNumber = data.bankNumber;
    this.approvementDate = data.approvementDate;
    this.checkingAccount = data.checkingAccount;
    this.checkingAccountDigit = data.checkingAccountDigit;
    this.consigneeContractKey = data.consigneeContractKey;
    this.contractDate = data.contractDate;
    this.consigneeName = data.consigneeName;
    this.contractStatus = data.contractStatus;
    this.loanType = data.loanType;
    this.createdLocation = data.createdLocation;
    this.embeddedIof = data.embeddedIof;
    this.extraInformation = data.extraInformation;
    this.installmentValue = data.installmentValue;
    this.interestRate = data.interestRate;
    this.financialInstitutionBankNumber = data.financialInstitutionBankNumber;
    this.financedValue = data.financedValue;
    this.hasRefinancedContracts = data.hasRefinancedContracts;
    this.numberOfInstallments = data.numberOfInstallments;
    this.outstandingBalance = data.outstandingBalance;
    this.paidInstallments = data.paidInstallments;
    this.factorCode = data.factorCode;
    this.person = data.person;
    this.remainingInstallments = data.remainingInstallments;
    this.validForSentToConsignee = data.validForSentToConsgnee;
    this.situation = data.situation;
    this.termsAccepted = data.termsAccepted;
    this.type = data.type;
    this.financialInstitution = data.financialInstitution;
    this.benefitAccountType = data.benefitAccountType;
    this.registerNumberDateOfIssue = data.registerNumberDateOfIssue;
    this.registerNumberEmitter = data.registerNumberEmitter;
    this.registerNumberState = data.registerNumberState;
    this.gender = data.gender;
  }

  get bankInfo() {
    return {
      bankName: this.bankName,
      bankNumber: this.bankNumber,
      checkingAccount: this.checkingAccount,
      checkingAccountDigit: this.checkingAccountDigit,
      agency: this.agency,
      agencyDigit: this.agencyDigit,
    };
  }

  get personInfo() {
    return this.person;
  }

  get agreementInfo() {
    const { agreementName, agreementId } = this.person || {};
    return { agreementName, agreementId };
  }

  get isFGTS() {
    return this.loanType === LOAN_TYPES.FGTS_NEW;
  }

  get isPAB() {
    return this.person?.agreementName === 'PAB';
  }

  get isINSSQiTech() {
    return this.person?.agreementName === 'INSS' && this.financialInstitution?.bankNumber === '329';
  }

  get isINSSPortQiTech() {
    return (
      this.person?.agreementName === 'INSS' &&
      this.loanType === 'PORTABILITY' &&
      this.financialInstitution?.bankNumber === '329'
    );
  }

  get loan() {
    return this.loanType;
  }

  get isParati() {
    return this.financialInstitution?.bankNumber === '326';
  }

  get object() {
    return {
      id: this.id,
      loanType: this.loanType,
      benefitAccountType: this.benefitAccountType,
      agency: this.agency,
      agencyDigit: this.agencyDigit,
      checkingAccount: this.checkingAccount,
      checkingAccountDigit: this.checkingAccountDigit,
      factorCode: this.factorCode,
      bankNumber: this.bankNumber,
      gender: this.gender,
      registerNumberDateOfIssue: this.registerNumberDateOfIssue,
      registerNumberEmitter: this.registerNumberEmitter,
      registerNumberState: this.registerNumberState,
      financialInstitution: this.financialInstitution,
      person: this.person,
    };
  }

  canSendContractToConsignee() {
    const { loanType, person, validForSentToConsignee, financialInstitution } = this;

    if (!validForSentToConsignee) return null;

    if (!person || !financialInstitution) {
      return null;
    }

    const { bankNumber } = financialInstitution;
    const { agreementName, agreementId } = person;

    const isBankTudoAndAgreementINSS = bankNumber === '326' && agreementName === 'INSS';
    const isEnableToQITech = bankNumber === '329' && loanType === 'NEW' && agreementId === 42;

    return (
      isBankTudoAndAgreementINSS ||
      loanType === 'FGTS_NEW' ||
      isEnableToQITech ||
      this.isPAB ||
      this.isINSSPortQiTech
    );
  }

  canUpdateBankInfo() {
    const { agreementName } = this.person || {};
    return this.benefitAccountType === 'Conta Corrente' && agreementName === 'INSS';
  }
}
