import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';
import { backoffice, third } from '~/services';

export const getCardDetails = createAsyncAction('GET_CARD_DETAIL', {
  saveResult: true,
  initialValue: {},
  context: {
    onRequestError: () => message.error('Erro ao consultar dados do cartão'),
    responseParser: ({ data }) => data,
    request: ({ id }) => backoffice.get(`/cards/details/${id}`),
  },
});

export const getTransactionHistory = createAsyncAction('GET_TRANSACTION_HISTORY', {
  saveResult: true,
  initialValue: [],
  context: {
    onRequestError: () => message.error('Erro ao consultar histórico de transações'),
    responseParser: ({ data }) => data || { transactions: [] },
    errorParser: () => [],
    request: ({ id, ...params }) => backoffice.get(`/cards/details/${id}/transactions`, { params }),
  },
});

export const updatePersonData = createAsyncAction('UPDATE_PERSON_DATA', {
  saveResult: true,
  initialValue: [],
  context: {
    onRequestError: () => message.error('Erro ao atualizar dados da pessoa'),
    responseParser: ({ data }) => data,
    errorParser: () => [],
    request: ({ personId, ...data }) =>
      third.post(`/integrations/inss/cards/${personId}/persongeneraldata`, data),
  },
});
