import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useModal } from '~/hooks/useModal';

import { Contract } from '~/typings/entities/contract';
import { fetchFinancialInstitutionsForDeposit } from '~/store/utilities/actions';

import { Form, OverflowProp } from './Form/Form';
import { Image } from './Image/Image';

import { Flex } from './Consignee.styled';

type ConsigneeProps = {
  contractId: number;
  contract?: Contract;
  onSubmit: (data: any) => void;
  overflow?: OverflowProp;
  modalId?: string;
  files?: any;
};

export function Consignee({
  contractId,
  contract,
  onSubmit,
  files,
  overflow,
  modalId,
}: ConsigneeProps) {
  const { isLoading } = useModal();

  const getFinancialInstitutionsControl = useAwaitControl(fetchFinancialInstitutionsForDeposit);

  const loading = modalId ? isLoading(modalId) : false;

  useEffect(() => {
    if (contractId) {
      getFinancialInstitutionsControl.start({ contractId });
    }
  }, []);

  return (
    <Flex>
      <Form contract={contract} onSubmit={onSubmit} overflow={overflow} loading={loading} />
      <Image files={files} />
    </Flex>
  );
}
