import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { ampli } from '~/ampli';

import { useModal } from '~/hooks/useModal';
import { useTracking } from '~/hooks/useTracking';
import { adjustContract, sendContract } from '~/store/consignee/actions';
import { getContractDetails, validateKYC } from '~/store/contracts/actions';
import { getFiles } from '~/store/files/actions';
import { getPersonDetail } from '~/store/people/actions';
import { Contract } from '~/typings/entities/contract';
import { ContractInfo } from '~/typings/entities/ContractInfo';
import { LOAN_TYPES } from '~/typings/enums/LoanType';
import { Consignee, OldActions as MenuAction } from '~/components';

interface SendToConsigneeProps {
  id: number;
}

function SendToConsignee({ id }: SendToConsigneeProps) {
  const { openModal, setLoading, closeAll } = useModal();
  const { handleTracking } = useTracking();

  const personControl = useAwaitControl(getPersonDetail);
  const personDetails = personControl.result();

  const getContractDetailsControl = useAwaitControl(getContractDetails);
  const contractError = getContractDetailsControl.hasFailure();
  const contract: Contract = getContractDetailsControl.result();

  const adjustContractControl = useAwaitControl(adjustContract);
  const adjustContractSuccessfully = adjustContractControl.isSuccessful();
  const adjustContractLoading = adjustContractControl.isRunning();

  const sendContractControl = useAwaitControl(sendContract);
  const sendContractSuccessfully = sendContractControl.isSuccessful();
  const sendContractLoading = sendContractControl.isRunning();

  const validateKYCControl = useAwaitControl(validateKYC);
  const validateKYCLoading = validateKYCControl.isRunning();

  const getFilesControl = useAwaitControl(getFiles);
  const getFilesLoading = getFilesControl.isRunning();
  const files = getFilesControl.result(`CONTRACT_${id}`);

  const details = new ContractInfo(contract);

  const isLoading = useMemo(
    () => adjustContractLoading || sendContractLoading || validateKYCLoading,
    [adjustContractLoading, sendContractLoading, validateKYCLoading],
  );

  const usedFutureMargin = useMemo(
    () => contract?.loanType === LOAN_TYPES.FUTUREMARGIN,
    [contract],
  );

  const handleSubmit = useCallback(
    (data) => {
      const action = usedFutureMargin ? adjustContractControl : sendContractControl;
      const { contractId } = contract;
      delete data.document;

      const trackEvent = ampli.conferenciaConcluiuConferenciaEnviouContrato({
        titulo_tela: 'Contratos',
        cliente_person_id: personDetails.id,
        cliente_convenio: personDetails.agreementName,
        cliente_oportunidade: personDetails.isOpportunity ?? false,
      });

      handleTracking(() => trackEvent);

      action.start({ contractId, data });

      if (!usedFutureMargin) {
        validateKYCControl.start({ contractId });
      }
    },
    [contract, personDetails],
  );

  const handleOpenModal = useCallback(() => {
    openModal(
      <Consignee contractId={id} contract={contract} onSubmit={handleSubmit} files={files} />,
      {
        id: 'send-contract-to-consignee',
        title: 'Envio de contrato para consignatária',
        width: 'auto',
        closable: true,
      },
    );
  }, [contract, id, files]);

  useEffect(() => {
    if (adjustContractSuccessfully || sendContractSuccessfully) {
      closeAll();
      adjustContractControl.clear();
      sendContractControl.clear();
    }
  }, [adjustContractSuccessfully, sendContractSuccessfully]);

  useEffect(() => {
    setLoading('send-contract-to-consignee', isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (id && !files?.length) {
      getFilesControl.start({ type: 'CONTRACT', id }, { actionId: `CONTRACT_${id}` });
    }
  }, [id]);

  if (contractError || !details.canSendContractToConsignee()) return null;

  return (
    <MenuAction
      key="sendToConsignee"
      onPress={handleOpenModal}
      title="Enviar para Consignatária"
      disabled={getFilesLoading}
    />
  );
}

export default SendToConsignee;
