import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { useModal } from '~/hooks/useModal';
import { Contract } from '~/typings/entities/Clix';
import { Button, Form, Input, Select, Typography } from '~/ui/components';
import { getOperationSteps, operationStep } from '~/store/contracts/actions';
import { getLoggedUser } from '~/store/user/actions';
import { InfoValue, Wrapper } from './OperationStep.styles';

type ChangeStepModalProps = {
  contract: Contract;
};

function ChangeStepModal({ contract }: ChangeStepModalProps) {
  const [selectedStep, setSelectedStep] = useState('');

  const { closeModal, setLoading } = useModal();

  const getOperationStepsControl = useAwaitControl(getOperationSteps);
  const operationStepsControl = useAwaitControl(operationStep);
  const loggedUserControl = useAwaitControl(getLoggedUser);

  const operationSteps = getOperationStepsControl.result();
  const loggedUser = loggedUserControl.result();
  const operationStepUpdated = operationStepsControl.isSuccessful();
  const isUpdating = operationStepsControl.isRunning();

  useEffect(() => {
    getOperationStepsControl.start();
  }, []);

  const stepOptions = useMemo(() => {
    if (!Array.isArray(operationSteps)) {
      return [];
    }

    return operationSteps.map((step) => ({
      value: step.context,
      label: step.name,
    }));
  }, [operationSteps]);

  const onSubmit = useCallback(
    (value) => {
      const step = operationSteps.find((opStep) => opStep.context === value.operationStepType);

      operationStepsControl.start({
        operationStep: step.context,
        externalUserId: loggedUser?.id,
        comment: value.comment,
        externalId: contract.contract.externalId,
        product: contract.contract.product,
      });
    },
    [operationSteps, loggedUser, contract],
  );

  const inputs = useMemo(
    () => [
      {
        id: 'operationStepType',
        input: (
          <Select
            options={stepOptions}
            placeholder="Selecione o novo passo"
            testId="operation-step"
            onChange={(value) => setSelectedStep(value[0])}
          />
        ),
      },
      {
        id: 'comment',
        input: (
          <Input textArea size="large" rows={4} placeholder="Escreva um comentário" id="comment" />
        ),
      },
    ],
    [stepOptions],
  );

  useEffect(() => {
    if (operationStepUpdated) {
      closeModal('operation-steps');
      operationStepsControl.clear();
    }
  }, [operationStepUpdated]);

  useEffect(() => {
    setLoading('operation-steps', isUpdating);
  }, [isUpdating]);

  return (
    <Form onSubmit={onSubmit} inputs={inputs}>
      <Wrapper>
        <Button
          title="Cancelar"
          variant="outline"
          rounded
          fullWidth
          type="button"
          size="md"
          color="black"
          onClick={() => closeModal('operation-steps')}
        />
        <Button
          title="Confirmar"
          variant="contained"
          rounded
          fullWidth
          disabled={!selectedStep}
          size="md"
          color="black"
          type="submit"
        />
      </Wrapper>
    </Form>
  );
}

interface OperationStepProps {
  step?: string;
  contract: Contract;
}

function OperationStep({ step, contract }: OperationStepProps) {
  const { openModal } = useModal();

  const handleOnClick = useCallback(() => {
    openModal(<ChangeStepModal contract={contract} />, {
      id: 'operation-steps',
      width: 490,
      title: 'Alterar passo da esteira',
      closable: true,
    });
  }, [openModal]);

  if (!step) return <Typography type="caption">-</Typography>;

  return (
    <InfoValue onClick={handleOnClick} data-testid="operation-step">
      {step}
    </InfoValue>
  );
}

export default memo(OperationStep);
