import React, { cloneElement, forwardRef, ReactNode } from 'react';
import { GetFieldDecoratorOptions, FormProps as AntFormProps } from 'antd/lib/form/Form';

import { ExtraContent, Flex, InputList, StyledForm } from '~/ui/components/Form/Form.styled';
import { Typography } from '../Typography/Typography';

export type FormInput = {
  id: string;
  label?: string | ReactNode;
  input?: ReactNode;
  width?: string;
  options?: GetFieldDecoratorOptions;
  initialValue?: any;
  hidden?: boolean;
  title?: string;
  labelHelp?: {
    title?: string;
    width?: number;
    content: ReactNode;
  };
};

export type FormProps = AntFormProps & {
  name: string;
  children: ReactNode;
  loading?: boolean | 0 | 1;
  onSubmit?: (values: any) => void;
  inputs?: FormInput[];
};

const Form = forwardRef(
  ({ children, form, onSubmit, loading, inputs, ...props }: FormProps, ref) => {
    const { getFieldDecorator } = form;

    const formatValues = (values) => {
      const newValues = {};

      Object.keys(values).forEach((key) => {
        newValues[key] =
          Array.isArray(values[key]) && values[key].length === 1 ? values[key][0] : values[key];
      });

      return newValues;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          onSubmit?.(formatValues(values));
        }
      });
    };

    const hasTitle = inputs.find((input) => input.title) ?? false;

    return (
      <StyledForm {...props} loading={loading} onSubmit={handleSubmit} ref={ref}>
        <InputList>
          {inputs?.map((input) => {
            const inputComponent = input.input
              ? cloneElement(input.input as any, { label: input.label })
              : null;

            return (
              <Flex widthValue={input.width} hasTitle={!!hasTitle}>
                {input.title && (
                  <Typography type="caption" weight={600} element="p">
                    {input.title}
                  </Typography>
                )}
                <StyledForm.Item
                  required={false}
                  key={input.id}
                  hidden={input?.hidden}
                  htmlFor={input.id}
                  colon={false}
                  className={input.input ? '' : 'no-input'}
                >
                  {inputComponent &&
                    getFieldDecorator(input.id, {
                      ...input?.options,
                      initialValue: input?.initialValue,
                    })(inputComponent)}
                </StyledForm.Item>
              </Flex>
            );
          })}
        </InputList>

        <ExtraContent>{children}</ExtraContent>
      </StyledForm>
    );
  },
);

export default StyledForm.create()(Form);
