import styled, { css } from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';
import theme from '~/ui/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-inline: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  margin-bottom: 25px;
  cursor: pointer;
  padding: 10px 0px;
  position: sticky;
  top: 0;
  background-color: ${themeColor('background.primary')};
  z-index: 1;
`;

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-inline: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${themeColor('brand.secondary', { dark: 'brand.primaryAlt' })};
`;

export const Content = styled.div<{ inProgress: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, inProgress }) =>
    inProgress ? theme.colors.alert.secondary : theme.colors.neutral.secondary};
  opacity: ${({ inProgress }) => (inProgress ? 0.8 : 1)};
  border-radius: 10px;
  gap: 4px;
  margin: 0 24px 16px 24px;
  cursor: pointer;
  padding: 16px;
`;

export const IconWrapper = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;

  svg {
    color: ${themeColor('element.primary')};
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type TextWrapperType = {
  position: string;
};

export const TextWrapper = styled.div<TextWrapperType>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ position }) =>
    position &&
    css`
      align-items: ${position};
    `}
`;

export const Title = styled(Typography).attrs(({ theme, size }) => ({
  type: 'paragraphSmall',
  element: 'div',
  size: size || 16,
  weight: 600,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Label = styled(Typography).attrs({
  type: 'caption',
  element: 'label',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
})`
  cursor: pointer;
`;

type ValueType = {
  score: number;
};

export const Value = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: theme.colors.element.primary,
}))<ValueType>`
  ${({ score }) =>
    score &&
    score >= 85 &&
    css`
      color: ${({ theme }) => theme.colors.positive.primaryAlt} !important;
      font-weight: 700;
    `}

  ${({ score }) =>
    score &&
    score >= 80 &&
    score < 85 &&
    css`
      color: ${({ theme }) => theme.colors.alert.primaryAlt} !important;
      font-weight: 700;
    `}

  ${({ score }) =>
    score &&
    score < 80 &&
    css`
      color: ${({ theme }) => theme.colors.negative.primaryAlt} !important;
      font-weight: 700;
    `}
`;
