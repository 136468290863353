import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { Box } from 'reflexbox';

import screenRegister, { HelmetTitle } from '~/hoc/screenRegister';
import * as ContractsActions from '~/store/contracts/actions';
import { Container, Card as Segment, Spinner } from '~/components';
import { ContractInfo } from '~/typings/entities/ContractInfo';

import { Alert, Flex } from '~/ui/components';
import { Link } from 'react-router-dom';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getLoggedUser } from '~/store/user/actions';
import { ampli } from '~/ampli';
import { useTracking } from '~/hooks/useTracking';
import { getContractDetails } from '~/store/contracts/actions';
import { CONTRACT_DETAIL_MODALS } from './modals';
import RefinAdjustment from './Actions/components/RefinAdjustment/RefinAdjustment';
import Actions from './Actions';
import Conference from './Conference';
import Panels from './Panels/Panels';
import Presentation from './Presentation';
import useContractDetails from './useContractDetails';

import { FlexWrapper } from './ContractDetails.Styles';

function ContractDetails() {
  const { id } = useParams<{ id: string }>();

  const {
    conferenceData,
    details,
    loggedUser,
    loading,
    isContractOwnerNotNull,
    isSentContract,
    isCancelled,
    needKycValidation,
    isConferenceByPersonEnabled,
  } = useContractDetails(id);

  const { result: contractDetails } = useAsyncActionStatus(getContractDetails);
  const { handleTracking } = useTracking();
  const loggedUserId = useAsyncActionStatus(getLoggedUser)?.result?.id;
  const contractInfo = useMemo(() => new ContractInfo(details), [details]);

  const mainGroupContract = useMemo(
    () => details?.groupContracts?.find((contract: any) => contract.lead),
    [details],
  );

  useEffect(() => {
    if (loggedUserId && contractInfo.personInfo && contractDetails) {
      const event = ampli.visualizacaoVisualizouTela({
        titulo_tela: 'Contrato',
        cliente_person_id: contractInfo.personInfo?.id,
        cliente_convenio: contractInfo.personInfo.agreementName,
        cliente_oportunidade: contractInfo.personInfo.isOpportunity,
        cliente_contrato_pago: contractDetails.contractStatus,
      });

      handleTracking(() => event);
    }
  }, [loggedUserId, contractInfo.personInfo, contractDetails]);
  return (
    <Container>
      <HelmetTitle title={details?.id ? `Contrato - ${id}` : 'Contrato'} />

      <Conference conferenceData={conferenceData} loggedUser={loggedUser} />

      {isConferenceByPersonEnabled && details.isGroupContract && details.isGroupLead && (
        <Flex width="100%" mb={21}>
          <Alert status="warning" fullWidth>
            Este contrato é o principal de um lote de contratos. As alterações feitas aqui serão
            replicadas para os demais contratos do lote.
          </Alert>
        </Flex>
      )}

      {isConferenceByPersonEnabled && details.isGroupContract && !details.isGroupLead && (
        <Flex width="100%" mb={21}>
          <Alert status="warning" fullWidth>
            Este contrato está vinculado a um lote específico. Para aplicar uma ação a todos, acesse
            o contrato{' '}
            <Link to={`/backoffice/contracts/${mainGroupContract?.contractId}`}>
              #{mainGroupContract?.contractId}
            </Link>
            .
          </Alert>
        </Flex>
      )}

      <Spinner spinning={loading} />

      <div>
        <FlexWrapper wrap align="flex-start">
          <Box w={3 / 4}>
            <Presentation person={details?.person} />
            <Segment>
              <Panels
                contractId={id}
                contractInfo={contractInfo}
                isConferenceByPersonEnabled={isConferenceByPersonEnabled}
              />
            </Segment>
          </Box>
          <Box w={1 / 4}>
            <Actions
              kycValidation={needKycValidation}
              conferenceData={conferenceData}
              isCancelled={isCancelled}
              contractDetails={details}
              showLinkContractOwner={isContractOwnerNotNull}
              showSendContract={isSentContract}
              isConferenceByPersonEnabled={isConferenceByPersonEnabled}
            />
          </Box>
        </FlexWrapper>
      </div>
    </Container>
  );
}

export default screenRegister({
  screenName: 'ContractDetails',
  path: '/backoffice/contracts/:id',
  headerTitle: 'Contrato',
  headerTitleSelector: (state) =>
    ContractsActions.getContractDetails.getResult()(state)?.contractId,
  modals: [...CONTRACT_DETAIL_MODALS, RefinAdjustment],
})(ContractDetails);
