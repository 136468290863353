import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useModal } from '~/hooks/useModal';
import { updatePersonData } from '~/store/cards/actions';

import { getDetails, approveContract } from '~/store/contracts/actions';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getLoggedUser } from '~/store/user/actions';
import { Consignee } from '~/components/Consignee/Consignee';
import { ActionItem } from '../Sidebar.styled';

function ApproveContractAction() {
  const { openModal, closeModal, setLoading } = useModal();
  const [data, setData] = useState<any>({});

  const approveContractControl = useAsyncActionStatus(approveContract);
  const loggedUserControl = useAsyncActionStatus(getLoggedUser);
  const detailsControl = useAsyncActionStatus(getDetails);
  const updatePersonControl = useAsyncActionStatus(updatePersonData);

  const result = useMemo(() => detailsControl.result, [detailsControl.result]);
  const contract = useMemo(() => result?.contract, [result]);
  const files = useMemo(() => result?.formalizationData?.documents, [result]);

  const isCancelled = detailsControl?.result?.logicalStatus === 'Cancelado';

  const actionDisabled = useMemo(
    () =>
      approveContractControl.loading ||
      detailsControl.loading ||
      loggedUserControl.loading ||
      isCancelled,
    [
      approveContractControl.loading,
      detailsControl.loading,
      loggedUserControl.loading,
      isCancelled,
    ],
  );

  const handleSubmit = useCallback(
    (formData) => {
      const updateData = {
        ...formData,
        document: formData?.document?.replace(/\D/g, ''),
      };

      updatePersonControl.start({ personId: result?.personData?.id, ...updateData });
      setData(updateData);
    },
    [contract],
  );

  useEffect(() => {
    if (updatePersonControl.success) {
      const { id } = loggedUserControl.result;

      approveContractControl.start({
        externalId: contract?.externalId,
        product: contract?.product,
        externalUserId: id,
        contractId: contract?.id,
        ...data,
      });
    }
  }, [updatePersonControl.success]);

  useEffect(() => {
    setLoading(
      'approve-contract-modal',
      approveContractControl.loading || updatePersonControl.loading,
    );
  }, [approveContractControl.loading, updatePersonControl.loading]);

  useEffect(() => {
    closeModal('approve-contract-modal');
    approveContractControl.clear();
    updatePersonControl.clear();
  }, [approveContractControl.success]);

  const handleOpenModal = useCallback(() => {
    approveContractControl.clear();

    openModal(
      <Consignee
        contractId={contract?.externalId}
        contract={result}
        onSubmit={handleSubmit}
        files={files}
        overflow="inherit"
      />,
      {
        id: 'approve-contract-modal',
        title: 'Envio de contrato para consignatária',
        width: 'auto',
        closable: true,
      },
    );
  }, [contract, files, result]);

  if (contract?.productSecurity || contract?.conferenceStatus === 'APPROVED') {
    return null;
  }

  return (
    <ActionItem disabled={actionDisabled} onClick={handleOpenModal}>
      Aprovar contrato
    </ActionItem>
  );
}

export default ApproveContractAction;
