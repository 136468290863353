import React, { useCallback, useMemo, useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import moment from 'moment';

import { AGREEMENT } from '~/common/constants';
import { useModal } from '~/hooks/useModal';
import { Button, Input, Select, SelectDate } from '~/ui/components';
import { Contract } from '~/typings/entities/contract';
import { Contract as ContractInfo } from '~/typings/entities/Clix';
import CIVIL_STATE from '~/constants/MaritalStatus';
import { fetchFinancialInstitutionsForDeposit } from '~/store/utilities/actions';
import UFs from '~/constants/UFs';
import GENDERS from '~/constants/Genders';
import toCPFFormat from '~/common/masked/toCPFFormat';
import { StyledForm } from '../Consignee.styled';

import { CONDITIONAL_INPUTS } from './inputs';

export type OverflowProp =
  | 'hidden'
  | 'visible'
  | 'scroll'
  | 'auto'
  | 'inherit'
  | 'initial'
  | 'unset';

type ConsigneeProps = {
  contract: Contract | ContractInfo | any;
  onSubmit: (data: any) => void;
  buttonText?: string;
  overflow?: OverflowProp;
  loading?: boolean;
};

export function Form({ contract, onSubmit, buttonText, overflow, loading }: ConsigneeProps) {
  const form = useRef<WrappedFormUtils>();

  const getFinancialInstitutionsControl = useAwaitControl(fetchFinancialInstitutionsForDeposit);
  const financialInstitutions = getFinancialInstitutionsControl.result();

  const mandatory = { rules: [{ required: true, message: 'Campo obrigatório!' }] };
  const selectorType = { label: 'name', value: 'value' };

  const handleSubmit = useCallback((data) => {
    onSubmit(data);
  }, []);

  const filterInputsByProduct = (initialInputs: any, product: string) => {
    const filteredInputs = initialInputs;

    const conditionalInputs = CONDITIONAL_INPUTS?.[product];

    if (conditionalInputs) {
      return initialInputs.filter((input) => conditionalInputs.includes(input.id));
    }

    return filteredInputs;
  };

  const inputs = useMemo(() => {
    const personData = contract?.personData || contract?.person;
    const benefitAccountType = personData?.benefitAccountType || contract?.benefitAccountType;
    const isINSS = personData?.agreementId === AGREEMENT.INSS;
    const isCheckingAccount = benefitAccountType === 'Conta Corrente';
    const isBankInfoEnabled = isCheckingAccount && isINSS;
    const defaultNationality = personData?.gender === 'MALE' ? 'Brasileiro' : 'Brasileira';
    const mothersName = personData?.mothersName || personData?.motherName;
    const registrationNumber = personData?.registerNumber || personData?.registrationNumber;
    const product = contract?.contract?.product || contract?.loanType;

    const bankNumber = personData?.bankNumber || contract?.bankNumber;
    const checkingAccount = personData?.checkingAccount || contract?.checkingAccount;
    const checkingAccountDigit = personData?.checkingAccountDigit || contract?.checkingAccountDigit;
    const agency = personData?.agency || contract?.agency;
    const agencyDigit = personData?.agencyDigit || contract?.agencyDigit;
    const factorCode = personData?.factorCode || contract?.factorCode;

    const initialInputs = [
      {
        id: 'name',
        label: 'Nome',
        title: 'Dados pessoais',
        initialValue: personData?.name,
        input: <Input label="Nome" />,
        options: mandatory,
      },
      {
        id: 'motherName',
        label: 'Nome da mãe',
        initialValue: mothersName,
        input: <Input label="Nome da mãe" />,
        options: mandatory,
      },
      {
        id: 'document',
        label: 'CPF',
        initialValue: personData?.document ? toCPFFormat(personData?.document) : '',
        input: <Input label="CPF" disabled={!!personData?.document} />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'registrationNumber',
        label: 'RG',
        initialValue: registrationNumber,
        input: <Input label="RG" />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'registerNumberEmitter',
        label: 'Orgão Emissor',
        initialValue: personData?.registerNumberEmitter,
        input: <Input label="Orgão Emissor" />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'registerNumberState',
        label: 'UF',
        initialValue: personData?.registerNumberState
          ? [personData?.registerNumberState]
          : personData?.registerNumberState,
        input: <Select options={UFs} label="UF" fieldNames={selectorType} />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'registerNumberDateOfIssue',
        label: 'Data de emissão',
        initialValue: moment(personData?.registerNumberDateOfIssue, 'DD-MM-YYYY').isValid()
          ? moment(personData?.registerNumberDateOfIssue, 'DD-MM-YYYY')
          : null,
        input: <SelectDate typeable />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'birthDate',
        label: 'Data de nascimento',
        initialValue: moment(personData?.birthDate, 'YYYY-MM-DD').isValid()
          ? moment(personData?.birthDate, 'YYYY-MM-DD')
          : null,
        input: <SelectDate typeable />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'maritalStatus',
        label: 'Estado civil',
        initialValue: personData?.maritalStatus
          ? [personData?.maritalStatus]
          : personData?.maritalStatus,
        input: <Select options={CIVIL_STATE} label="Estado civil" fieldNames={selectorType} />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'gender',
        label: 'Sexo',
        initialValue: personData?.gender ? [personData?.gender] : null,
        input: <Select options={GENDERS} label="Sexo" fieldNames={selectorType} />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'nationality',
        label: 'Nacionalidade',
        initialValue: personData?.nationality ?? defaultNationality,
        input: <Input label="Nacionalidade" />,
        options: mandatory,
        width: 'calc(40% - 12px)',
      },
      {
        id: 'cityOfBirth',
        label: 'Cidade de nascimento',
        initialValue: personData?.cityOfBirth,
        input: <Input label="Cidade de nascimento" />,
        options: mandatory,
        width: 'calc(40% - 12px)',
      },
      {
        id: 'stateOfBirth',
        label: 'UF de nasc.',
        initialValue: personData?.stateOfBirth,
        input: <Input label="Estado" />,
        options: mandatory,
        width: 'calc(20% - 12px)',
      },
      {
        id: 'street',
        label: 'Endereço',
        title: 'Endereço',
        initialValue: personData?.street,
        input: <Input label="Endereço" />,
        options: mandatory,
        width: 'calc(80% - 12px)',
      },
      {
        id: 'addressNumber',
        label: 'Número',
        initialValue: personData?.addressNumber,
        input: <Input label="Número" />,
        options: mandatory,
        width: 'calc(20% - 12px)',
      },
      {
        id: 'district',
        label: 'Bairro',
        initialValue: personData?.district,
        input: <Input label="Bairro" />,
        options: mandatory,
        width: 'calc(50% - 12px)',
      },
      {
        id: 'city',
        label: 'Cidade',
        initialValue: personData?.city,
        input: <Input label="Cidade" />,
        options: mandatory,
        width: 'calc(50% - 12px)',
      },
      {
        id: 'state',
        label: 'Estado',
        initialValue: personData?.state,
        input: <Input label="Estado" />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'zipCode',
        label: 'CEP',
        initialValue: personData?.zipCode,
        input: <Input label="CEP" />,
        options: mandatory,
        width: 'calc(25% - 12px)',
      },
      {
        id: 'bankNumber',
        label: 'Banco',
        title: 'Dados bancários',
        initialValue: bankNumber ? [bankNumber] : null,
        input: (
          <Select
            options={financialInstitutions}
            label="Banco"
            fieldNames={{ label: 'name', value: 'bankNumber' }}
            // disabled={!isBankInfoEnabled}
          />
        ),
        options: mandatory,
      },
      {
        id: 'checkingAccount',
        label: 'Conta',
        initialValue: checkingAccount,
        input: <Input label="Conta" disabled={!isBankInfoEnabled} />,
        options: mandatory,
        width: 'calc(35% - 12px)',
      },
      {
        id: 'checkingAccountDigit',
        label: 'Dígito',
        initialValue: checkingAccountDigit,
        input: <Input label="Dígito" disabled={!isBankInfoEnabled} />,
        options: mandatory,
        width: 'calc(15% - 12px)',
      },
      {
        id: 'agency',
        label: 'Agência',
        initialValue: agency,
        input: <Input label="Agência" disabled={!isBankInfoEnabled} />,
        options: mandatory,
        width: 'calc(35% - 12px)',
      },
      {
        id: 'agencyDigit',
        label: 'Dígito',
        initialValue: agencyDigit,
        input: <Input label="Dígito" disabled={!isBankInfoEnabled} />,
        options: mandatory,
        width: 'calc(15% - 12px)',
      },
      {
        id: 'factorCode',
        label: 'Código do Produto na Consignatária',
        initialValue: factorCode,
        tooltip: 'test',
        input: <Input label="Código do Produto na Consignatária" />,
        options: mandatory,
      },
    ];

    return filterInputsByProduct(initialInputs, product);
  }, [mandatory, financialInstitutions]);

  return (
    <StyledForm
      id="form"
      ref={form}
      name="consignee-form"
      onSubmit={handleSubmit}
      inputs={inputs}
      overflow={overflow}
    >
      <Button rounded type="submit" loading={loading}>
        {buttonText || 'Enviar'}
      </Button>
    </StyledForm>
  );
}
