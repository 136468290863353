import React, { useState } from 'react';

import { Button } from '~/ui/components';
import { ControlImage } from '~/components';
import { Flex } from './Image.styled';

type ImageProps = {
  files: any;
};

export function Image({ files }: ImageProps) {
  const [isFront, setIsFront] = useState(true);

  const getImage = (filesList) => {
    let image;

    const DOCUMENTFRONTRG = filesList?.find(
      (file) => file?.type === 'DOCUMENTFRONT' || file?.personImageType === 'DOCUMENT_FRONT',
    );

    const DOCUMENTBACKRG = filesList?.find(
      (file) => file?.type === 'DOCUMENTBACK' || file?.personImageType === 'DOCUMENT_BACK',
    );

    if (DOCUMENTFRONTRG) {
      image = isFront ? DOCUMENTFRONTRG : DOCUMENTBACKRG;
    }

    return image?.url || image?.imageUrl;
  };

  return (
    <Flex id="image">
      <ControlImage alt="Documento" imagePath={getImage(files)} />
      <Button onClick={() => setIsFront(!isFront)} rounded variant="outline" size="sm">
        {isFront ? 'Ver verso' : 'Ver frente'}
      </Button>
    </Flex>
  );
}
