import styled, { css } from 'styled-components';

import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';
import theme from '~/ui/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-inline: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  margin-bottom: 25px;
  cursor: pointer;
  padding: 10px 0px;
  position: sticky;
  top: 0;
  background-color: ${themeColor('background.primary')};
  z-index: 1;
`;

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-inline: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${themeColor('brand.secondary', { dark: 'brand.primaryAlt' })};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 24px;
  margin-bottom: 25px;
  padding-inline: 5px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  ${({ bold }) =>
    bold &&
    css`
      p,
      a {
        color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })} !important;
        font-weight: 700 !important;
      }
    `}
`;

export const Label = styled(Typography).attrs({
  type: 'paragraphSmall',
  element: 'label',
  weight: 600,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
})``;

export const Value = styled(Typography).attrs(({ theme, link }) => ({
  type: 'paragraphSmall',
  element: 'span',
  weight: link ? 700 : 400,
  color: link
    ? themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' })
    : themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  p {
    margin-bottom: 0;
  }
`;
