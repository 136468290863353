import * as React from 'react';
import {
  StyledButton,
  Sizes,
  StyledButtonProps,
  CircleLoading,
} from '~/ui/components/Button/Button.styled';
import { Typography } from '~/ui/components';
import { TypographyTypes } from '~/ui/theme/typography';
import * as icons from '~/ui/assets/icons';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { forwardRef, useMemo } from 'react';

type IconsTypes = keyof typeof icons;

export type ButtonProps = {
  title?: string;
  icon?: IconsTypes | '';
  children?: React.ReactNode | string;
  iconPosition?: 'right' | 'left';
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
  type?: 'button' | 'submit' | 'reset';
} & Omit<StyledButtonProps, 'invert'>;

type TypographyTypeSizes = { [type in Sizes]: TypographyTypes };

export const TypographyTypeSizeMap: TypographyTypeSizes = {
  lg: 'headingSmall',
  md: 'paragraphSmall',
  sm: 'caption',
};

export const Button = forwardRef(
  (
    {
      title,
      size = 'md',
      color = 'primary',
      iconPosition = 'left',
      variant = 'contained',
      customColor,
      children,
      disabled,
      icon,
      iconOnly,
      className,
      loading,
      tooltip,
      tooltipPlacement = 'right',
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const typographyType = TypographyTypeSizeMap[size] as TypographyTypes;
    const IconComponent = loading ? icons.LoadingSpinner : icons?.[icon];
    const isIconOnly = iconOnly || (icon && !(title || children));

    const button = (
      <StyledButton
        ref={ref}
        size={size}
        disabled={disabled || loading}
        color={color !== 'black' ? color : undefined}
        iconOnly={isIconOnly}
        customColor={color === 'black' ? '#1A1A1A' : customColor}
        invert={iconPosition === 'right'}
        variant={variant}
        className={className}
        title={title}
        {...props}
      >
        {(icon || loading) && <IconComponent />}

        {(title || children) && !iconOnly && (
          <Typography type={typographyType} element="span" weight={700}>
            {children || title}
          </Typography>
        )}
      </StyledButton>
    );

    const buttonWithTooltip = useMemo(
      () => (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          {button}
        </Tooltip>
      ),
      [tooltip, tooltipPlacement, button],
    );

    return tooltip ? buttonWithTooltip : button;
  },
);
